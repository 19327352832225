/* ----------------------------------------------------------------------------
Component: Checkbox
---------------------------------------------------------------------------- */
.c-checkbox {
    position: relative;
}

.c-checkbox:before {
    content: '';
    display: block;
    width: 30px;
    height: 30px;
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
    border: 2px solid palette(neutral, darkest);
}

.c-checkbox_inverse:before {
    border-color: palette(neutral, lightest);
}

.c-checkbox_center {
    width: 34px;
    height: 34px;
    margin: 0 auto;
}

.c-checkbox-field {
    width: auto;
    opacity: 0;
    height: 0;
    position: absolute;
    background: none;
}

.c-checkbox-label {
    display: inline-block;
    box-sizing: border-box;
    padding: 10px 0 10px 54px;
    @include font-size(15px);
    color: palette(neutral, darkest);
    text-transform: uppercase;
    cursor: pointer;
}

.c-checkbox-label_inverse {
    color: palette(neutral, lightest);
}

.c-checkbox-label_dim {
    color: palette(neutral, mid);
}

.c-checkbox-label_isHidden {
    padding-left: 0;
    width: 34px;
    height: 34px;
    font-size: 0;
}

.c-checkbox-field ~ .c-checkbox-label:before,
.c-checkbox-field ~ .c-checkbox-label:after {
    content: '';
    display: block;
    width: 1px;
    height: 42px;
    background-color: transparent;
    position: absolute;
    left: 17px;
    top: -4px;
    pointer-events: none;
    transform-origin: center center;
    transition: background-color .2s ease;
}

.c-checkbox-field ~ .c-checkbox-label:before {
    transform: rotate(45deg);
}

.c-checkbox-field ~ .c-checkbox-label:after {
    transform: rotate(-45deg);
}

.c-checkbox-field:checked ~ .c-checkbox-label:before,
.c-checkbox-field:checked ~ .c-checkbox-label:after {
    background-color: palette(neutral, darkest);
}

.c-checkbox-field:checked ~ .c-checkbox-label_inverse:before,
.c-checkbox-field:checked ~ .c-checkbox-label_inverse:after,
.c-checkbox-field:checked ~ .c-checkbox-label_dim:before,
.c-checkbox-field:checked ~ .c-checkbox-label_dim:after {
    background-color: palette(neutral, lightest);
}
