/* ----------------------------------------------------------------------------
Component: Form
---------------------------------------------------------------------------- */
.c-form {
    position: relative;
}

.c-form-hd,
.c-form-snippet,
.c-form-subFt {
    text-align: center;
}

.c-form-hd {
    margin-bottom: 40px;
}

.c-form-hd_spaced {
    margin-bottom: 103px;
}

.c-form-hd_tight {
    margin-bottom: 26px;
}

.c-form-action {
    position: absolute;
    top: 0;
    right: 0;
}

.c-form-snippet {
    margin-bottom: 36px;
    color: palette(neutral, dark);
    font-family: font(family, condensed);
    font-weight: font(weight, bold);
    @include font-size(18px);
    line-height: 1.3;
}

.c-form-snippet_spaced {
    margin-bottom: 65px;
}

.c-form-bd {
    color: palette(neutral, dark);
}

.c-form-subFt {
    margin-top: 46px;
}

.c-form-ft {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    box-shadow: 0 -7px 50px palette(brand, base);
}
