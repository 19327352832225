/* ----------------------------------------------------------------------------
Component: Horizontal Rule
---------------------------------------------------------------------------- */
.c-hr {
    @include hr();
    margin: 12px 0 20px 0;
}

.c-hr_minor {
    border-color: palette(neutral, mid);
    border-image: none;
}

.c-hr_push {
    margin-bottom: 32px;
}

.c-hr_topFlush {
    margin-top: 0;
}
