/* ----------------------------------------------------------------------------
Component: Dropdown
Styled like a select form element, but is a list of links instead
---------------------------------------------------------------------------- */
.c-dropdown {
    height: $FORM_CONTROL_HEIGHT;
    position: relative;
    z-index: $Z_INDEX_DROPDOWN;
    cursor: pointer;
}

// Author - Infosys
// Description - to Avoid overlapping with other dropdown
.c-dropdown.s-isActive {
    z-index: $Z_INDEX_DROPDOWN + 1;
}

.c-dropdown:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: calc(50% + 6px); /* magic numbers to match designs */
    right: 13px;
    transform: translateY(-50%);
    border-top: 8px solid palette(neutral, darkest);
    border-right: 6px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none;
}

.s-isActive.c-dropdown:after {
    display: none;
}

.c-dropdown-list {
    height: $FORM_CONTROL_HEIGHT;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

.s-isActive .c-dropdown-list {
    height: auto;
    overflow: auto;
    box-shadow: 1px 1px 4px palette(neutral, mid);
}

// Author - Infosys
// Description - To show dropdown setup based on data-component
.s-isActive .c-dropdown-list-new {
    height: $FORM_CONTROL_HEIGHT;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    overflow: hidden;
}

// Author - Infosys
// Description - To show dropdown setup based on data-component
.s-isActive .s-isActive .c-dropdown-list-new {
    height: auto;
    overflow: auto;
    box-shadow: 1px 1px 4px palette(neutral, mid);
}

.c-dropdown-list-new  {
     height: $FORM_CONTROL_HEIGHT;
     width: 100%;
     position: absolute;
     top: 0;
     left: 0;
     overflow: hidden;
 }

.c-dropdown-list-item {
    display: flex;
    align-items: center;
    height: $FORM_CONTROL_HEIGHT;
    background: palette(neutral, x-light);
}

.s-isActive .c-dropdown-list-item:hover,
.s-isActive .c-dropdown-list-item > *:hover {
    background: palette(neutral, light);
}

.c-dropdown-list-item > * {
    width: 100%;
    position: relative;
    padding: 14px;
    box-sizing: border-box;
    color: palette(neutral, x-dark);
    cursor: pointer;
}

.c-dropdown-list-item > *:hover {
    text-decoration: none;
}

.c-dropdown-list-item > a {
    pointer-events: none;
}

.s-isActive .c-dropdown-list-item > a {
    pointer-events: auto;
}
