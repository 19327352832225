//----------------------------------------------------------------------------
// Tools: Mixins
//----------------------------------------------------------------------------
@mixin font-size($size) {
    font-size: $size;
    font-size: calculateRem($size);
}


// Button Background Mixin
//
// Mixin that creates hover and active states based off initial background color
//
// $bg-color - Base background color of your button.
//
// Usage - `@include button-bg(palette(brand, light));`
//
@mixin button-bg($bg-color) {
    background: $bg-color;
    transition: background-color $DEFAULT_TRANSITION;

    &:hover {
        background-color: darken($bg-color, 6%);
        text-decoration: none;
    }

    &:focus,
    &:active {
        background: darken($bg-color, 8%);
    }
}

@mixin hr() {
    border: 0;
    border-bottom: 1px solid palette(neutral, light);
    -moz-border-image: -moz-linear-gradient(left, palette(neutral, x-light), palette(neutral, light), palette(neutral, x-light));
    -webkit-border-image: -webkit-linear-gradient(left, palette(neutral, x-light), palette(neutral, light), palette(neutral, x-light));
    border-image: linear-gradient(to right, palette(neutral, x-light), palette(neutral, light), palette(neutral, x-light));
    border-image-slice: 1;
}


// Clearfix Mixin
//
// Mixin to add a clearfix to components
//
// Usage - `@include clearfix();`
//
@mixin clearfix() {
    &:after {
        content: "";
        display: table;
        clear: both;
    }
}

// Link Color Mixin
//
// Mixin that creates hover and active states based off initial color.
//
// $hsl_function: lighten or darken
// $color: base color of the link
//
// Usage - `@include link-color(darken, palette(brand, light));`
//
@mixin link-color($hsl_function, $color) {
    color: $color;
    transition: color $DEFAULT_TRANSITION;

    @if $hsl_function == 'lighten' {
        &:hover {
            color: lighten($color, 15%);
        }

        &:focus,
        &:active {
            color: lighten($color, 20%);
        }
    }

    @if $hsl_function == 'darken' {
        &:hover {
            color: darken($color, 15%);
        }

        &:focus,
        &:active {
            color: darken($color, 20%);
        }
    }
}
