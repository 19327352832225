/* ----------------------------------------------------------------------------
Component: Navigation List
---------------------------------------------------------------------------- */
.c-navList-item {
    max-width: 100%;
}

.c-navList-item-link {
    display: block;
    padding: 25px 20px;
    box-sizing: border-box;
    @include font-size(10px);
    font-weight: font(weight, bold);
    letter-spacing: 1px;
    text-align: center;
    text-transform: uppercase;
    transition: background-color $DEFAULT_TRANSITION;
}

.s-isActive > .c-navList-item-link,
.c-navList-item-link:hover,
.c-navList-item-link:active {
    background-color: palette(neutral, light);
    transition: background-color $DEFAULT_TRANSITION;
}
