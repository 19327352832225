/* ----------------------------------------------------------------------------
Elements: Base
---------------------------------------------------------------------------- */
html {
    font-size: 100%;
}

html,
body {
    height: 100%;
    background-color: palette(neutral, lightest);
    color: palette(neutral, x-dark);
    font-family: font(family, base);
    @include font-size(14px);
    line-height: $BASE_LINE_HEIGHT;
}

section {
    min-height: 1px; // deals with IE giant whitespace
}

a {
    color: palette(brand, light);
    text-decoration: none;
}

a:hover {
    text-decoration: underline;
}

img {
    display: block;
    height: auto;
    max-width: 100%;
}

p:not(:last-child) {
    margin-bottom: 26px;
}

/* TODO: Should probably be handle via class name */
input[type=file] {
    display: none;
}

tr {
    page-break-inside: avoid;
}
