/* ----------------------------------------------------------------------------
Component: Error
---------------------------------------------------------------------------- */
.c-error {
    display: none;
    margin-bottom: 5px;
    padding: 8px;
    background: palette(accent, alert);
    @include font-size(12px);
    color: palette(neutral, lightest);
    letter-spacing: 1px;
    text-transform: uppercase;
}

.c-error.s-isActive {
    display: block;
}
