/* ----------------------------------------------------------------------------
Object: oList
---------------------------------------------------------------------------- */
.o-oList {
    list-style-type: decimal;
}

.o-oList_inset {
    padding-left: 14px;
}

.o-oList > * + * {
    margin-top: 20px;
}