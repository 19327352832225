/* ----------------------------------------------------------------------------
Component: Copy (Text)
---------------------------------------------------------------------------- */
.c-copy_xs {
    @include font-size(10px);
}

.c-copy_sm {
    @include font-size(12px);
}

.c-copy_med {
    @include font-size(15px);
}

.c-copy_lg {
    @include font-size(18px);
}

.c-copy_bold {
    font-weight: font(weight, bold);
}

.c-copy_brand {
    color: palette(brand, light);
}

.c-copy_white {
    color: palette(neutral, lightest);
}

.c-copy_mid {
    color: palette(neutral, mid);
}

.c-copy_dark {
    color: palette(neutral, dark);
}

.c-copy_xdark {
    color: palette(neutral, x-dark);
}

.c-copy_success {
    color: palette(accent, success);
}

.c-copy_alert {
    color: palette(accent, alert);
}

.c-copy_uppercase {
    text-transform: uppercase;
}

.c-copy_centerAlign {
    text-align: center;
}

.c-copy_rightAlign {
    text-align: right;
}

.c-copy_indent {
    margin-left: 1em;
}

.c-copy_attention {
    letter-spacing: $WEIGHTY_LETTER_SPACING;
}
