/* ----------------------------------------------------------------------------
Component: Drawer
max-height is set by JS for more control over dynamic data
---------------------------------------------------------------------------- */
$drawer-WIDTH-SM: 215px;
$drawer-ZINDEX-LOW: 100;
$drawer-ZINDEX-HIGH: 500;

.c-drawer {
    width: 100%;
}

.c-drawer_sm {
    position: relative;
    display: inline-block;
    width: $drawer-WIDTH-SM;
}

.c-drawer_emphasize {
    padding-top: 16px;
    padding-bottom: 0;
    transition: background-color $DEFAULT_TRANSITION;
}

.c-drawer_emphasize.s-isActive {
    background-color: palette(neutral, x-light);
    transition: background-color $DEFAULT_TRANSITION;
}

.c-drawer-hd .c-icon {
    transition: transform 0.15s cubic-bezier(0.65, 0.05, 0.36, 1);
}

.s-isActive > .c-drawer-hd [data-drawer-icon] {
    transform: rotate(270deg);
}

.c-drawer-bd {
    max-height: 0;
    overflow: hidden;
    visibility: hidden;
    will-change: max-height;
    transition: max-height $DEFAULT_TRANSITION,
                visibility $DEFAULT_TRANSITION;
}

// Author - Infosys
// Description - To show dropdown setup based on data-component
.c-drawer-bd-overflow {
    overflow: visible;
}


.s-isActive > .c-drawer-bd {
    visibility: visible;
}

.c-drawer-bd-inner {
    padding-top: 25px;
    padding-bottom: 25px;
    box-sizing: border-box;
}

.c-drawer-bd-inner_flush {
    padding-top: 0;
}

.c-drawer_sm > .c-drawer-bd {
    position: absolute;
    top: 36px;
}

.c-drawer_sm .c-drawer-bd-inner {
    padding-right: 25px;
    padding-left: 25px;
    border-right: 1px solid palette(neutral, light);
    border-bottom: 1px solid palette(neutral, light);
    border-left: 1px solid palette(neutral, light);
    box-sizing: border-box;
    background: palette(neutral, x-light);
    font-size: 15px;
}

.c-drawer-reveal {
    visibility: hidden;
}

.s-isActive .c-drawer-reveal {
    visibility: visible;
}
