/* ----------------------------------------------------------------------------
Trumps: Base Utilities
---------------------------------------------------------------------------- */
.u-isDisabled {
    pointer-events: none;
    cursor: not-allowed;
}

.u-uppercase {
    text-transform: uppercase;
}

.u-noEvents {
    pointer-events: none;
}