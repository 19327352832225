/* ----------------------------------------------------------------------------
Object: Site
---------------------------------------------------------------------------- */
$site-ASIDE_WIDTH: 114px;
$site-BOOKEND_HEIGHT: 60px;
$site-SIDEBAR_WIDTH: 400px;
$site-TOPBAR_HEIGHT: 60px;
$site-TOPBAR_DOCUMENT_HEIGHT: 78px;

.o-site {
    box-sizing: border-box;
    display: flex;
    height: 100vh;
    padding-left: $site-ASIDE_WIDTH;
}

.o-site_themeDark {
    padding: 0;
    background-color: palette(brand, base);
}

.o-site-aside {
    display: flex;
    flex-direction: column;
    align-items: center;
    box-sizing: border-box;
    position: fixed;
    padding: 15px 0;
    width: $site-ASIDE_WIDTH;
    height: 100vh;
    overflow-y: auto;
    top: 0;
    left: 0;
    background: palette(neutral, x-light);
}

.o-site-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
}

.o-site-content-topBar {
    box-sizing: border-box;
    padding: 24px 0 19px 0;
    height: $site-BOOKEND_HEIGHT;
    width: calc(100% - #{$site-ASIDE_WIDTH});
    position: fixed;
    top: 0;
    right: 0;
    z-index: $Z_INDEX_TOPBAR;
    background: palette(brand, base);
}

.o-site-content-topBar_center {
    width: 100%;
    height: $site-TOPBAR_DOCUMENT_HEIGHT;
    padding: 19px 0;
}

.o-site-content-main {
    display: flex;
    flex-direction: row;
    flex: 1 0 auto;
    margin-bottom: -$site-BOOKEND_HEIGHT;
    padding-top: $site-BOOKEND_HEIGHT;
    padding-bottom: $site-BOOKEND_HEIGHT;
}

.o-site-content-footer {
    align-self: flex-start;
    width: 100%;
    height: $site-BOOKEND_HEIGHT;
}

.o-site-content-main-bd {
    width: 100%;
}

.o-site-content-main-bd_document {
    flex: 1 0 auto;
    padding-top: $site-TOPBAR_DOCUMENT_HEIGHT;
}

.o-site-content-main-sidebar {
    width: $site-SIDEBAR_WIDTH;
    margin-bottom: -$site-BOOKEND_HEIGHT;
}

.o-site-content_hasSidebar .o-site-content-main-bd,
.o-site-content_hasSidebar .o-site-content-footer {
    width: calc(100% - #{$site-SIDEBAR_WIDTH});
}
