/* ----------------------------------------------------------------------------
Component: Search
---------------------------------------------------------------------------- */
.c-search {
    position: relative;
    border-bottom: 2px solid palette(neutral, x-light);
}

// Use specificity to override reset
.c-search-input.c-search-input {
    box-sizing: border-box;
 }

.c-search-input {
    display: block;
    height: $FORM_CONTROL_HEIGHT;
    width: 100%;
    margin: 0;
    padding: 8px;
    border-radius: 0;
    border: none;
    appearance: none;
    background: transparent;
    color: palette(neutral, x-dark);
    font-family: font(family, base);
    @include font-size(14px); // Safari clipping descenders of placeholder text when 13px
    letter-spacing: 1px;
}

.c-search-input_inverse {
    color: palette(neutral, lightest);
}

// Magnifying glass
.c-search-icon {
    position: absolute;
    top: 50%;
    right: 16px;
    transform: translateY(-50%);
}

.c-search-input:focus .c-search-icon_sumbit {
    display: none;
}

.c-search-icon_reset {
    display: none;
}

.c-search-input:focus .c-search-icon_reset {
    display: block;
}

/* ----------------------------------------------------------------------------
 Placeholder classes
---------------------------------------------------------------------------- */
.c-search-input {
    &::-webkit-input-placeholder {
        font-style: italic;
    }

    &::-moz-placeholder {
        font-style: italic;
    }

    &:-moz-placeholder {
        font-style: italic;
    }

    &:-ms-input-placeholder {
        font-style: italic;
    }
}
