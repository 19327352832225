/* ----------------------------------------------------------------------------
Component: Tier
---------------------------------------------------------------------------- */
.c-tier {
    padding: 48px 0;
    background-color: palette(neutral, lightest);
}

.c-tier_branded {
    padding: 165px 0;
    background-color: palette(brand, base);
}

.c-tier_ghost {
    background-color: transparent;
}

.c-tier-pullTop {
    padding-top: 80px;
}
