/* ----------------------------------------------------------------------------
Object: Media
---------------------------------------------------------------------------- */
$media-BASE_SPACING: 12px;

.o-media {
    display: table;
    @include clearfix();
}

.o-media-hd {
    float: left;
    margin-right: $media-BASE_SPACING;
}

.o-media-bd {
    overflow: hidden;
}

.o-media-bd_middleAlign {
    display: table-cell;
    vertical-align: middle;
}

.o-media-bd_bottomAlign {
    display: table-cell;
    vertical-align: bottom;
}