/* ----------------------------------------------------------------------------
Component: Tabs
---------------------------------------------------------------------------- */
.c-tabs {
    display: flex;
    flex-basis: auto;
    flex-wrap: nowrap;
}

.c-tabs-item {
    width: 100%;
    border: 1px solid palette(neutral, mid);
}

.c-tabs-item:not(:first-child) {
    border-left: 0;
}

.c-tabs-item-link {
    display: flex;
    align-items: center;
    height: $FORM_CONTROL_HEIGHT_SM;
    @include button-bg(palette(neutral, lightest));
    color: palette(neutral, xx-dark);
    padding: 2px 8px;
}

.c-tabs-item.s-isActive > .c-tabs-item-link {
    @include button-bg(palette(neutral, xx-dark));
    color: palette(neutral, lightest);
}

.c-tabs-item-link-text {
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: $WEIGHTY_LETTER_SPACING;
}
