/* ----------------------------------------------------------------------------
Object: Grid

Grid cols require XofXX selectors in order for overall grid with to be correct
---------------------------------------------------------------------------- */
$grid-GUTTER_BASE: 8px;
$grid-GUTTER_COUNT_MAX: 8;
$grid-COUNT_MAX: 12;

.o-grid {
    display: flex;
    flex-wrap: nowrap;
    margin-right: -$grid-GUTTER_BASE;
}

.o-grid_inset {
    margin-right: -32px;
}

.o-grid_centerAlign {
    align-items: center;
}

.o-grid_bottomAlign {
    align-items: flex-end;
}

.o-grid_justifyCenter {
    justify-content: center;
}

// Generate grid gutters based on base gutter
//
// EXAMPLE OUTPUT
//    .o-grid_x2 { margin-left: 16px; }
//    .o-grid_x3 { margin-left: 24px; }
//    .o-grid_x4 { margin-left: 32px; }
//
@for $i from 2 through $grid-GUTTER_COUNT_MAX {

    .o-grid_x#{$i} > .o-grid-col + .o-grid-col {
        margin-left: $i * $grid-GUTTER_BASE;
    }

}

.o-grid-col {
    width: 100%;
    min-width: 1px;
}

.o-grid-col_centerAlign {
    text-align: center;
}

.o-grid-col_rightAlign {
    text-align: right;
}

.o-grid-col + .o-grid-col {
    margin-left: $grid-GUTTER_BASE;
}

// Generate grid cols based on base gutter
//
// EXAMPLE OUTPUT
//
//    .o-grid-col_1of12 { width: xx%; }
//    .o-grid-col_2of12 { width: xx%; }
//    .o-grid-col_3of12 { width: xx%; }
//    .o-grid-col_4of12 { width: xx%; }
//
@for $i from 1 through $grid-COUNT_MAX {
    $width: $i * (100% / $grid-COUNT_MAX);

    .o-grid-col_#{$i}of#{$grid-COUNT_MAX} {
        width: calc(#{$width} - #{$grid-GUTTER_BASE});
    }
}

// Generate grid gutter variants
// Generate grid cols based on gutter variants
//
// EXAMPLE OUTPUT
//    .o-grid_x2 > .o-grid-col + .o-grid-col { margin-left: 16px; }
//    .o-grid_x3 > .o-grid-col + .o-grid-col { margin-left: 24px; }
//    .o-grid_x4 > .o-grid-col + .o-grid-col { margin-left: 32px; }
//
@for $i from 2 through $grid-GUTTER_COUNT_MAX {
    $grid-GUTTER: $i * $grid-GUTTER_BASE;

    .o-grid_x#{$i} {
        
        > .o-grid-col + .o-grid-col {
            margin-left: $grid-GUTTER;
        }

        @for $i from 1 through $grid-COUNT_MAX {
            $width: $i * (100% / $grid-COUNT_MAX);

            > .o-grid-col_#{$i}of#{$grid-COUNT_MAX} {
                width: calc(#{$width} - #{$grid-GUTTER});
            }
        }
    }
}
