/* ----------------------------------------------------------------------------
Component: Image Placeholder
---------------------------------------------------------------------------- */
$imagePlacehold-DIMENSION: 260px;

.c-imagePlacehold {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    border: 1px solid palette(neutral, lightest);
    box-sizing: border-box;
}

.c-imagePlacehold_square {
    width: $imagePlacehold-DIMENSION;
    height: $imagePlacehold-DIMENSION;
}

.c-imagePlacehold_light {
    border: 1px solid palette(neutral, light);
}

.c-imagePlacehold-icon {
    fill: palette(neutral, lightest);
}

.c-imagePlacehold_light > .c-imagePlacehold-icon {
    fill: palette(neutral, light);
}
