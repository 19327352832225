/* ----------------------------------------------------------------------------
Component: Drawer Print Styles
Drawers must be open to expose data for printing
---------------------------------------------------------------------------- */

// Requires .o-site specificity to avoid opening the top bar drawer
.o-site-content-main .c-drawer-bd {
    max-height: none;
    visibility: visible;
}

.c-drawer-bd-inner_flush {
    background-color: #f5f5f5;
}
