/* ----------------------------------------------------------------------------
Component: Field
---------------------------------------------------------------------------- */
.c-field {
    display: block;
    margin: 0;
    padding: 0;
    width: 100%;
    font-family: font(family, base);
    border-radius: 0;
    box-sizing: border-box;
    border: none;
    appearance: none;
    background: palette(neutral, x-light);
    color: palette(neutral, x-dark);
}

.c-field_text {
    height: $FORM_CONTROL_HEIGHT;
    padding: 8px;
    border: 4px solid transparent;
}

// Removes IE default select arrow
.c-field_text::-ms-expand {
    display: none;
}

.c-field_textarea {
    padding: 8px;
    border: 4px solid transparent;
    resize: none;
}

.c-field_ghost {
    height: $FORM_CONTROL_HEIGHT;
    padding: 8px 0;
    background: transparent;
    color: palette(neutral, lightest);
}

.c-field_textareaGhost {
    height: auto;
    padding: 8px 0;
    background: transparent;
    color: palette(neutral, lightest);
}

/* Error State */
.s-hasError.c-field_text,
.s-hasError.c-field_textarea {
    border-color: palette(accent, alert);
    color: palette(accent, alert);
}

.c-field_inverse {
    background: palette(neutral, lightest);
}

/* ----------------------------------------------------------------------------
 Placeholder classes
---------------------------------------------------------------------------- */
.c-field {
    &::-webkit-input-placeholder {
        color: palette(neutral, dark);
    }

    &::-moz-placeholder {
        color: palette(neutral, dark);
    }

    &:-moz-placeholder {
        color: palette(neutral, dark);
    }

    &:-ms-input-placeholder {
        color: palette(neutral, dark);
    }
}

.s-hasError.c-field_text {
    &::-webkit-input-placeholder {
        color: palette(accent, alert);
    }

    &::-moz-placeholder {
        color: palette(accent, alert);
    }

    &:-moz-placeholder {
        color: palette(accent, alert);
    }

    &:-ms-input-placeholder {
        color: palette(accent, alert);
    }
}
