/* ---------------------------------------------------------------------
 Modern Styles
------------------------------------------------------------------------ */
@media screen, print {
    @import "_screen";
}

@media print {
    @import "_print";
}
