/* ----------------------------------------------------------------------------
Component: Indicator
---------------------------------------------------------------------------- */

.c-indicator {
    position: relative;
    margin: 0 auto;
    width: 200px;
    height: 200px;
}

.c-indicator-shape {
    position: absolute;
    z-index: -10;
    width: 100%;
    height: 100%;
    background-color: palette(neutral, x-dark);
    border-radius: 100px;
}

.c-indicator-label {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 10;
    width: 100%;
    text-align: center;
}
