/* ---------------------------------------------------------------------
 Object: Blocks
------------------------------------------------------------------------ */
$blocks-SPACING_UNIT: 30px;
$blocks-COUNT_MAX: 5;

.o-blocks {
    display: flex;
    flex-wrap: wrap;
    margin: (-$blocks-SPACING_UNIT) 0 0 (-$blocks-SPACING_UNIT); /* offset blocks horizontal and vertical spacing - must match padding of blocks items */
}

.o-blocks > * {
    padding: $blocks-SPACING_UNIT 0 0 $blocks-SPACING_UNIT; /* space blocks horizontally and vertically - offset is handled by .o-blocks */
    box-sizing: border-box;
    overflow-wrap: break-word;
    word-wrap: break-word; // IE support
}

@for $i from 2 through $blocks-COUNT_MAX {

    .o-blocks_#{$i}up > * {
        width: 100% / $i;
    }

}
// EXAMPLE OUTPUT
//    .o-blocks_2up { width: 50%; }
//    .o-blocks_3up { width: 33.33%; }
//    .o-blocks_4up { width: 25%; }
//    .o-blocks_5up { width: 20%; }
