/* ----------------------------------------------------------------------------
Component: Button
---------------------------------------------------------------------------- */
.c-btn {
    box-sizing: border-box;
    display: inline-block;
    border: none;
    margin: 0;
    padding: 0 8px;
    height: $FORM_CONTROL_HEIGHT_SM;
    cursor: pointer;
    color: palette(neutral, lightest);
    text-transform: uppercase;
    text-align: center;
    letter-spacing: $WEIGHTY_LETTER_SPACING;
    line-height: 1.231;
    @include font-size(13px);
    font-family: font(family, base);
    text-decoration: none;
    user-select: none;
    appearance: none;
    @include button-bg(palette(brand, light));
}

.c-btn[disabled] {
    background-color: palette(neutral, mid);
    color: palette(neutral, lightest);
    opacity: 0.3;
    cursor: not-allowed;
}

/* Button Colors */
.c-btn_accent {
    @include button-bg(palette(neutral, x-dark));
}

.c-btn_dim {
    @include button-bg(palette(neutral, xx-dark));
    color: palette(neutral, lightest);
}

.c-btn_inverse {
    @include button-bg(palette(neutral, lightest));
    color: palette(neutral, xx-dark);
}

/* Button Decorations */
.c-btn_unstyled {
    padding: 0;
    height: auto;
    border: 0;
    @include button-bg(transparent);
    color: inherit;
    font-size: inherit;
    letter-spacing: inherit;
    text-transform: inherit;
    overflow: visible;
}

.c-btn_centerAlign {
    justify-content: center;
}

.c-btn_leftAlign {
    text-align: left;
}

/* Button Sizes */
.c-btn_lg {
    height: $FORM_CONTROL_HEIGHT_LRG;
    padding: 32px 16px;
    @include font-size(14px);
}

.c-btn_mega {
    height: 120px;
}

.c-btn_fullHeight {
    height: 100%;
}

.c-btn_stackedIcon {
    position: relative;
}

.c-btn_stackedIcon > * {
    position: absolute;
    left: 29%;
    top: 50%;
    transform: translateY(-50%);
}

.c-btn_elongated {
    padding-left: 28px;
    padding-right: 28px;
}

.c-btn_full {
    display: block;
    width: 100%;
}

.c-btn_hasChild {
    display: flex;
    align-items: center;
}

.c-btn_hasChild > * {
    width: 100%;
}