/* ----------------------------------------------------------------------------
Object: vList
---------------------------------------------------------------------------- */
$vList-BASE_UNIT: 20px;
$vList-COUNT_MAX: 5;

.o-vList > * {
    display: block;
}

.o-vList > * + * {
    margin-top: $vList-BASE_UNIT;
}

.o-vList_pipe > * {
    padding-bottom: $vList-BASE_UNIT;
}

.o-vList_pipe > *:not(:last-child) {
    @include hr();
}

@for $i from 2 through $vList-COUNT_MAX {

    .o-vList_x#{$i} > * + * {
        margin-top: $i * $vList-BASE_UNIT;
    }

}
// EXAMPLE OUTPUT
//    .o-vList_x2 { margin-top: 40px; }
//    .o-vList_x3 { margin-top: 60px; }
//    .o-vList_x4 { margin-top: 80px; }
//    .o-vList_x5 { margin-top: 100px; }

.o-vList_half > * + * {
    margin-top: $vList-BASE_UNIT/2;
}