/* ----------------------------------------------------------------------------
Component: Select
---------------------------------------------------------------------------- */
.c-select {
    position: relative;
}

.c-select:after {
    content: '';
    display: block;
    width: 0;
    height: 0;
    position: absolute;
    top: calc(50% + 6px); /* magic numbers to match designs */
    right: 13px;
    transform: translateY(-50%);
    border-top: 8px solid palette(neutral, darkest);
    border-right: 6px solid transparent;
    border-bottom: 8px solid transparent;
    border-left: 6px solid transparent;
    pointer-events: none;
}