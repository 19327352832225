/* ----------------------------------------------------------------------------
Component: Heading
---------------------------------------------------------------------------- */
.c-hdg {
    color: palette(neutral, darkest);
    font-family: font(family, condensed);
    font-weight: font(weight, bold);
    line-height: 1.231;
    text-transform: uppercase;
}

.c-hdg_huge {
    @include font-size(72px);
}

.c-hdg_1 {
    @include font-size(35px);
}

.c-hdg_2 {
    @include font-size(30px);
}

.c-hdg_3 {
    @include font-size(24px);
}

.c-hdg_4 {
    @include font-size(20px);
}

.c-hdg_5 {
    @include font-size(18px);
}

.c-hdg_inverse {
    color: palette(neutral, lightest);
}

.c-hdg_brand {
    color: palette(brand, light);
}

.c-hdg_xdark {
    color: palette(neutral, x-dark);
}

.c-hdg_full {
    display: block;
}

.c-hdg_understate {
    text-transform: none;
}
