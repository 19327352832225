/* ----------------------------------------------------------------------------
Object: table
---------------------------------------------------------------------------- */
$table-COUNT_MAX: 12;

.o-table {
    width: 100%;
    overflow-wrap: break-word;
    word-wrap: break-word; // IE support
}

.o-table_fixed {
    table-layout: fixed;
}

.o-table_indent th:first-child,
.o-table_indent td:first-child {
    padding-left: 16px;
}

.o-table_roomy .o-table-row > * {
    padding-top: 16px;
    padding-bottom: 16px;
}

.o-table_pipe > tr:not(.o-table-row_major):not(:last-child),
.o-table_pipe > tbody > tr:not(.o-table-row_major):not(:last-child) {
    @include hr();
}

.o-table-hd {
    display: table-header-group;
}

.o-table-hd_bottomAlign th {
    vertical-align: bottom;
}

.o-table-row > * {
    padding: 8px;
    text-align: left;
    vertical-align: middle;
    page-break-inside: avoid;
    vertical-align: middle;
}

.o-table-row_inset > * {
    padding-left: 11px;
}


.o-table-row_major {
    border: 1px solid palette(neutral, light);
    box-shadow: -1px -1px 1px palette(neutral, light), 1px 2px 2px palette(neutral, mid), 2px 3px 3px palette(neutral, dark);
}

.o-table-row_bold {
    font-weight: font(weight, bold);
}

.o-table-row-col {
    vertical-align: text-top;
}

// Generate table columns
//
// EXAMPLE OUTPUT
//
//    .o-table-row-col_1of12 { width: xx%; }
//    .o-table-row-col_2of12 { width: xx%; }
//    .o-table-row-col_3of12 { width: xx%; }
//    .o-table-row-col_4of12 { width: xx%; }
//
@for $i from 1 through $table-COUNT_MAX {
    $width: $i * (100% / $table-COUNT_MAX);

    .o-table-row-col_#{$i}of#{$table-COUNT_MAX} {
        width: #{$width};
    }
}

.o-table-row-col_micro {
    width: 42px;
}

.o-table-cell_centerAlign {
    text-align: center;
}

.o-table-cell_rightAlign {
    text-align: right;
    padding-right: 0;
}

.o-table-cell_topAlign {
    vertical-align: top;
}

.o-table-row.s-isLoading {
    opacity: 0.5;
}
