/* ----------------------------------------------------------------------------
Component: Link
---------------------------------------------------------------------------- */


.c-link {
    display: inline-block;
    @include link-color(lighten, palette(brand, light));
}

.c-link:hover {
    text-decoration: none;
}

.c-link_emphasis {
    padding-bottom: 2px;
    border-bottom: 1px solid currentColor;
    transition: border-bottom $DEFAULT_TRANSITION;
}

.c-link_emphasisHidden { // necessary when text in link needs to be unlinked
    padding-bottom: 3px ;
}

.c-link_text {
    color: palette(neutral, mid);
    font-weight: font(weight, bold);
    text-transform: uppercase;
}

.c-link_text.s-isActive {
    color: palette(neutral, x-dark);
}

// No state changes
.c-link_dim,
.c-link_dim:hover,
.c-link_dim:active,
.c-link_dim:focus, {
    color: palette(neutral, xx-dark);
}

// No state changes
.c-link_inverse,
.c-link_inverse:hover,
.c-link_inverse:active,
.c-link_inverse:focus {
    color: palette(neutral, lightest);
}

.c-link_inverseAnimate {
    @include link-color(darken, palette(neutral, lightest));
}

.c-link_underline {
    text-decoration: underline;
}

// State Changes for Pagination
.c-link.s-isInactive {
    visibility: hidden;
}

.c-link.s-isActive {
    visibility: visible;
}

.c-link-list > * {
    display: inline-block;
}

.c-link-list > * + * {
    margin-left: 8px;
    border-left: 2px solid palette(neutral, mid);
    padding-left: 8px;
}
