/* ----------------------------------------------------------------------------
Object: Button Group
---------------------------------------------------------------------------- */
.o-btnGroup {
    display: flex;
}

.o-btnGroup_joust > * {
    width: 100%;
}

.o-btnGroup_joust > * + * {
    margin-left: 20px;
}

.o-btnGroup-item {
    flex-grow: 1;
}
