/* ----------------------------------------------------------------------------
Object: Wrapper
---------------------------------------------------------------------------- */
.o-wrapper {
    margin: 0 auto;
    padding: 0 $WRAPPER_GUTTER;
    max-width: 100%;
}

.o-wrapper_lrg {
    width: $WRAPPER_WIDTH_LRG;
}

.o-wrapper_mid {
    width: $WRAPPER_WIDTH;
}

.o-wrapper_sm {
    width: $WRAPPER_WIDTH_SM;
}

.o-wrapper_constrained {
    width: $WRAPPER_CONSTRAINED_WIDTH;
}

.o-wrapper_rightFlush {
	padding-right: 0;
}