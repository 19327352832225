/* ----------------------------------------------------------------------------
Modules: Layout: Vertical Rhythm
---------------------------------------------------------------------------- */
$vr-BASE_UNIT: 6px;
$vr-COUNT_MAX: 16;

.o-vr {
    margin-bottom: $vr-BASE_UNIT;
}

@for $i from 2 through $vr-COUNT_MAX {

    .o-vr_x#{$i} {
        margin-bottom: $i * $vr-BASE_UNIT;
    }

}

// EXAMPLE OUTPUT
//    .o-vr_x2 { margin-bottom: 8px; }
//    .o-vr_x3 { margin-bottom: 12px; }
//    .o-vr_x4 { margin-bottom: 16px; }
//    .o-vr_x5 { margin-bottom: 20px; }
//