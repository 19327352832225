/* ----------------------------------------------------------------------------
Object: Site
---------------------------------------------------------------------------- */

.o-site {
    padding-left: 0;
}

.o-site-aside.o-site-aside {
    display: none;
}

.o-site-content-topBar {
    width: 100%;
}
