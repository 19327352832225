/* ----------------------------------------------------------------------------
Object: hList
---------------------------------------------------------------------------- */
$hList-BASE_UNIT: 10px;
$hList-COUNT_MAX: 4;


/* TODO: Would like to see this component reworked to a simpler flexbox pattern */
.o-hList {
    display: inline-block;
    margin-bottom: -$hList-BASE_UNIT;
    margin-right: -$hList-BASE_UNIT;
    margin-left: -$hList-BASE_UNIT;
    font-size: 0; // removes inline-block white space
}

.o-hList_flex {
    display: flex;
}

.o-hList > * {
    display: inline-block;
    margin-bottom: $hList-BASE_UNIT;
    padding-right: $hList-BASE_UNIT;
    padding-left: $hList-BASE_UNIT;
    font-size: $BASE_FONT_SIZE; // reset font size
    text-align: left;
    vertical-align: baseline;
}

.o-hList > *:last-child {
    margin-right: 0;
}

.o-hList_topAlign > * {
    vertical-align: top;
}

.o-hList_middleAlign > * {
    vertical-align: middle;
}

.o-hList_piped > * {
    @include font-size(12px);
}

.o-hList_piped > *:not(:first-child) {
    border-left: 1px solid currentColor;
}

@for $i from 2 through $hList-COUNT_MAX {

    .o-hList_x#{$i} {
        // margin-bottom: -($i * $hList-BASE_UNIT);
        margin-right: -($i * $hList-BASE_UNIT);
        margin-left: -($i * $hList-BASE_UNIT);
    }

    .o-hList_x#{$i} > * {
        // padding-bottom: $i * $hList-BASE_UNIT;
        padding-right: $i * $hList-BASE_UNIT;
        padding-left: $i * $hList-BASE_UNIT;
    }
}
// EXAMPLE OUTPUT
//    .o-hList_x2 { margin-right: 20px; }
//    .o-hList_x3 { margin-right: 30px; }
//    .o-hList_x4 { margin-right: 40px; }
