/* ----------------------------------------------------------------------------
Component: Icon
---------------------------------------------------------------------------- */
.c-icon {
    border: 0;
    display: inline-block;
    vertical-align: baseline;
    background-color: transparent;
    fill: palette(neutral, darkest);
}

.c-icon_brand {
    fill: palette(brand, light);
}

.c-icon_x-dark {
    fill: palette(neutral, x-dark);
}

.c-icon_dim {
    fill: palette(neutral, xx-dark);
}

.c-icon_inverse {
    fill: palette(neutral, lightest);
}

.c-icon_success {
    fill: palette(accent, success);
    padding-bottom: 2px;
    padding-left: 5px;
}

.c-icon_alert {
    fill: palette(accent, alert);
    padding-top: 2px;
    padding-right: 5px;
}

.c-icon_rotate90 {
    transform: rotate(90deg);
}

.c-icon_rotate180 {
    transform: rotate(180deg);
}

.c-icon_rotate270 {
    transform: rotate(270deg);
}

.c-icon_middleAlign {
    vertical-align: middle;
}

