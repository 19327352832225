/* ----------------------------------------------------------------------------
Trumps: Svg Utilities
---------------------------------------------------------------------------- */
.u-brandFill {
    fill: palette(brand, base);
}

.u-whiteFill {
    fill: palette(neutral, lightest);
}

.u-transparentFill {
    fill: none;
}

.u-brandStroke {
    stroke: palette(brand, base);
}

.u-whiteStroke {
    stroke: palette(neutral, lightest);
}

.u-dimStroke {
    stroke: #7b7b7f;
}