/* ----------------------------------------------------------------------------
Component: Label
---------------------------------------------------------------------------- */
.c-label {
    display: inline-block;
    margin: 0 0 10px 2px;
    position: relative;
    color: palette(neutral, mid);
    @include font-size(12px);
    font-weight: normal;
    letter-spacing: 1px;
    text-transform: uppercase;
    @include clearfix();
}

.c-label_insetTop {
    padding-top: 12px;
}

.c-label_featured {
    display: block;
    padding-bottom: 11px;
    margin-bottom: 30px;
    border-bottom: 1px solid palette(neutral, mid);
}

.c-label-supContainer {
    position: relative;
}

.c-label-sup {
    position: absolute;
    top: -3px;
    right: -12px;
    color: palette(neutral, lightest);
    @include font-size(18px);
}

.c-label_sm {
    margin-bottom: 6px;
    @include font-size(10px);
}

.c-label_prefix {
    margin-bottom: 0;
    white-space: nowrap;
}

.c-label_leftAlign {
    text-align: left;
}

.c-label_sortable {
    position: relative;
    padding-right: 10px;
}
