/* ----------------------------------------------------------------------------
Trumps: Visibility Utilities
---------------------------------------------------------------------------- */
.u-isVisuallyHidden {
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
    position: absolute;
    clip: rect(0 0 0 0);
    overflow: hidden;
}

.u-isHidden {
    display: none;
}