/* ----------------------------------------------------------------------------
Object: Split
---------------------------------------------------------------------------- */
.o-split {
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}

.o-split_vertical {
    flex-direction: column;
}

.o-split_topAlign {
    align-items: flex-start;
}

.o-split_bottomAlign {
    align-items: flex-end;
}

.o-split-item {
    width: auto;
}

.o-split-item_full {
    width: 100%;
}

.o-split-item + .o-split-item {
    margin: 0 0 0 20px;
}

.o-split_vertical > .o-split-item + .o-split-item {
    margin: 5% 0 0 0;
}
