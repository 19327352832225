/* ---------------------------------------------------------------------
 Object: Aspect Ratio
------------------------------------------------------------------------ */
.o-aspectRatio {
    display: block; /* So the class can be set on inline elements and have width and height */
    position: relative; /* So the child can be positioned relative to this container */
}

.o-aspectRatio > * {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.o-aspectRatio:before {
    content: '';
    display: block;
    width: 100%;
    height: 0;
}

.o-aspectRatio_1x1 {
    padding-bottom: 100%;
}

.o-aspectRatio_3x2 {
    padding-bottom: 66.67%;
}

/* So it moves into place on top of pseudo-element placeholder */
.o-aspectRatio_1x1 > *,
.o-aspectRatio_3x2 > *,  {
    position: absolute;
}

.o-aspectRatio-item_centerAlign > * {
    display: block;
    max-height: 100%;
    max-width: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
}
