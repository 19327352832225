/* ----------------------------------------------------------------------------
Elements: Typography
---------------------------------------------------------------------------- */
b,
strong {
    font-weight: font(weight, bold);
}

em,
i {
    font-style: italic;
}