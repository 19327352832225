/* ----------------------------------------------------------------------------
Vendor: Awesomplete

This styling is from a 3rd party plugin, so doesn't follow Nerdery Standards
exactly. This CSS is based off the provided plugin stylesheet which can be
found here: https://github.com/LeaVerou/awesomplete/blob/gh-pages/awesomplete.css
---------------------------------------------------------------------------- */
.visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
}

.awesomplete {
    position: relative;
}

.awesomplete-inner {
    background: palette(neutral, lightest);
    width: 100%;
    position: absolute;
    left: 0;
    overflow: auto;
    background: palette(neutral, lightest);
    box-shadow: 0 1px 4px rgba(0,0,0,.4);
    z-index: 20;
}

.s-isExpanded .awesomplete-inner {
    height: 224px;
}

.awesomplete-inner > ul {
    position: absolute;
    left: 0;
    z-index: 1;
    min-width: 100%;
    box-sizing: border-box;
    list-style: none;
    padding: 12px 0;
    border-radius: 1px;
    background: palette(neutral, lightest);
    text-shadow: none;
}

.awesomplete-inner > ul[hidden],
.awesomplete-inner > ul:empty {
    display: none;
}


.awesomplete-inner > ul[hidden],
.awesomplete-inner > ul:empty {
    opacity: 0;
    display: block;
}

.awesomplete-inner > ul > li {
    position: relative;
    padding: 12px;
    @include font-size(16px);
    color: palette(brand, light);
    cursor: pointer;
}

.awesomplete-inner > ul > li:hover,
.awesomplete-inner > ul > li[aria-selected="true"] {
    background: palette(brand, light);
    color: palette(neutral, lightest);
}

.awesomplete-inner mark {
    background: none;
    color: palette(brand, light);
}

.awesomplete-inner li:hover mark,
.awesomplete-inner li[aria-selected="true"] mark {
    background: none;
    color: palette(neutral, lightest);
}