/* ---------------------------------------------------------------------
Component: Modal
-----------------------------------------------------------------------*/
.c-modal {
    display: none;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0; 
    left: 0;
    background-color: palette(brand, base);
    opacity: 0;
    transform: translateZ(0) scale(0.94);
    z-index: $Z_INDEX_MODAL;
    transition: transform .15s ease,
                opacity .15s ease;
}

.c-modal.s-isActive {
    opacity: 1;
    transform: translateZ(0) scale(1);
    transition: transform .15s ease,
                opacity .25s ease;
}

.c-modal-dismiss {
    position: absolute;
    top: 35px;
    right: 41px;
}

.c-modal-dismiss_inset {
    top: 94px;
    right: 79px;
}

.c-modal-dismiss-btn {
    background-color: transparent;
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 0;
    appearance: none;
    cursor: pointer;
}

.c-modal-bd {
    box-sizing: border-box;
    padding: 140px 0;
    height: 100%;
    overflow-y: auto;
}

.c-modal-ft {
    width: 100%;
    position: fixed;
    left: 0;
    bottom: 0;
    box-shadow: 0 -7px 50px palette(brand, base);
}