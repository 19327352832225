/* ----------------------------------------------------------------------------
Object: Grid

Grid cols require XofXX selectors in order for overall grid with to be correct
Print styles require CSS2.1 for PDF export plugin to display properly (no flexbox)
---------------------------------------------------------------------------- */
.o-grid {
    display: table;
    table-layout: fixed;
    width: 100%;
}

.o-grid-col {
    display: table-cell;
    padding: 8px;
}
